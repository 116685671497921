<template>
  <b-form @submit.prevent='submitForm' class='form-inputs--mobile--lg'>
    <b-input-group class="my-3">
      <div class="d-flex w-100">
        <div class="flex-grow-1">
          <select-key-value
            :options="companiesList"
            v-model="companySelect"
            v-on:option:selected="loadCompanyData"
            placeholder="Inserisci i dati della società o seleziona una precedentemente inserita"
            title="Elenco aziende"
            id="companiesInvoiceId"
          />
        </div>
      </div>
    </b-input-group>
    <loader-info v-if="loadCompanyInfo" />
    <b-card v-else>
      <b-form-row>
        <b-col md="12" lg="12">
          <field-text
            fieldId="companyName"
            placeholder="Ragione Sociale"
            title="Ragione Sociale"
            :state="validateState($v.formInvoiceCompany.info.companyName)"
            v-model="$v.formInvoiceCompany.info.companyName.$model"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md='12'>
          <field-address
            title='Sede Legale'
            field-id="legalAddress"
            v-model="$v.formInvoiceCompany.legalAddress.$model"
            :state="fieldStateAddressReference"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md='12'>
          <field-billing
            title='Dati di fatturazione'
            field-id='invoiceReference'
            v-model='$v.formInvoiceCompany.invoiceReference.$model'
            :state='fieldStateInvoiceReference'
            is-company
          />
        </b-col>
      </b-form-row>
    </b-card>
  </b-form>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import lodash from 'lodash';

import {
  checkVatNumber,
  decodeFormFieldError,
  fieldStateAddress, fieldStateInvoice, isNotEmpty, omitNull,
  VALIDATE_ADDRESS,
  validateFormFieldState,
} from '@/utils/validators';
import FieldBilling from '@/components/fields/FieldBilling.vue';
import FieldAddress from '@/components/fields/FieldAddress.vue';
import FieldText from '@/components/fields/FieldText.vue';
import SelectKeyValue from '@/components/helpers/SelectKeyValue.vue';
import LoaderInfo from '@/components/helpers/LoaderInfo.vue';

export default {
  name: 'formInvoiceCompany',
  components: {
    LoaderInfo,
    SelectKeyValue,
    FieldText,
    FieldAddress,
    FieldBilling,
  },
  props: {
    companiesInvoice: Array,
  },
  data() {
    return {
      loadCompanyInfo: false,
      formInvoiceCompany: {
        info: {
          companyName: null,
          // vatNumber: null,
        },
        legalAddress: {
          type: 'LEGAL',
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        invoiceReference: {
          taxCode: null,
          vatNumber: null,
          sdiCode: null,
          invoicePecEmail: {
            type: 'PEC',
            contactData: null,
          },
        },
      },
      clientValidation: {
        formInvoiceCompany: {
          info: {
            companyName: {
              required,
            },
            // vatNumber: {
            //   required,
            // },
          },
          legalAddress: {
            ...VALIDATE_ADDRESS,
          },
          invoiceReference: {
            taxCode: {
              required,
              checkVatNumber,
            },
            vatNumber: {
              checkVatNumber,
            },
            invoicePecEmail: {
              contactData: {
                email,
              },
            },
          },
        },
      },
      serverValidation: {},
      // companiesInvoice: [],
      companySelect: null,
      // companySelect: {
      //   key: 'new-company',
      //   value: 'Inserisci i dati della società o seleziona una precedentemente inserita',
      // },
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    fieldStateAddressReference() {
      return fieldStateAddress(this.$v.formInvoiceCompany.legalAddress);
    },
    fieldStateInvoiceReference() {
      return fieldStateInvoice(this.$v.formInvoiceCompany.invoiceReference);
    },
    companiesList() {
      // const list = [{ key: 'new-company', value: 'Inserisci i dati della società o seleziona una precedentemente inserita' }];

      const companies = this.companiesInvoice.map((item) => {
        const info = item.additionalInfos.company || { companyName: '', taxCode: '' };

        return {
          key: info.taxCode,
          value: `${info.companyName}`,
        };
      });
      console.log('companiesList', this.companiesInvoice, companies);
      return companies;
    },
  },
  validations() {
    return this.rules;
  },
  mounted() {
    this.loadCompaniesList();
  },
  watch: {
    formInvoiceCompany: {
      handler(newData, oldData) {
        console.log('watch', newData, oldData);
        this.$v.$touch();
        this.$emit('invoice-company-data', {
          invoiceData: newData,
          invalidForm: this.$v.$invalid,
        });
      },
      deep: true,
    },
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    loadCompaniesList() {
      this.populateForm();
      // try {
      //   this.$store.dispatch('subject/loadCompaniesInvoice')
      //     .then((companies) => {
      //       console.debug('companies', companies);
      //       this.companiesInvoice = companies || [];
      //     }).catch((error) => console.error('fetch company', error));
      // } catch (error) {
      //   console.error('loadCompaniesList error', error);
      // }
    },
    populateForm(companyInfo) {
      // const company = this.companiesInvoice;
      const company = companyInfo || {};

      const deepcloned = lodash.cloneDeep(company);

      const empty = {
        info: {
          companyName: null,
          vatNumber: null,
        },
        legalAddress: {
          type: 'LEGAL',
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        invoiceReference: {
          taxCode: null,
          vatNumber: null,
          sdiCode: null,
          invoicePecEmail: {
            type: 'PEC',
            contactData: null,
          },
        },
      };

      if (isNotEmpty(deepcloned)) {
        this.formInvoiceCompany = { ...empty, ...omitNull(deepcloned) };
      } else {
        this.formInvoiceCompany = { ...empty };
      }
      this.$v.$touch();
    },
    submitForm() {
      return false;
    },
    loadCompanyData() {
      console.log('loadCompanyData', this.companySelect);
      const company = this.companiesInvoice.find((item) => {
        console.log('item', item);
        const info = item.additionalInfos.company || { companyName: '', taxCode: '' };

        return this.companySelect === info.taxCode;
      });

      if (company) {
        this.loadCompanyInfo = true;
        this.$store.dispatch('subject/loadCompanyDetail', company.childEntity.entityId)
          .then((response) => {
            console.log('loadCompanyData', response);
            this.populateForm(response);
          })
          .finally(() => {
            this.loadCompanyInfo = false;
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
